import React, { useState } from 'react';
// import logo from './logo.svg';
// import './App.css';
import './App.scss';
// eslint-disable-next-line
import Login from './Login';
// eslint-disable-next-line
import Select from './Select';
// eslint-disable-next-line
import Medi from './Medi';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import API from './util/api';

function App() {
	// TODO: autentykacja i sprawdzenie czy jest zalogowany !
	const [initialized, setInitialized] = useState(false);
	const [loggedUser, setLoggedUser] = useState(null);

	const [message, setMessage] = useState('Wczytywanie ...');

	if (!initialized) {
		API.getLoggedUser()
			.then(v => {
				if (v.data.ok) {
					setInitialized(true);
					setLoggedUser(v.data.user);
				} else {
					setInitialized(true);
					setLoggedUser(null);
				}
			})
			.catch(e => {
				// console.log('error !');
				setMessage('Błąd podczas wczytywania - spróbuj ponownie!');
			});
		return (
			<div className="App">
				<Router>{message}</Router>
			</div>
		);
	} else {
		return (
			<div className="App">
				<Router>
					{loggedUser ? (
						<Switch>
							<Route path="/balans">
								<Medi name="balans" />
							</Route>
							<Route path="/reduktor">
								<Medi name="reduktor" />
							</Route>
							<Route path="/up">
								<Medi name="up" />
							</Route>
							<Route path="/spacemind">
								<Medi name="spacemind" />
							</Route>
							<Route path="/kids">
								<Medi name="kids" />
							</Route>
							<Route path="/select">
								<Select />
							</Route>
							<Route path="/">
								<Select />
							</Route>
						</Switch>
					) : (
						<Login luState={[loggedUser, setLoggedUser]} />
					)}
				</Router>
			</div>
		);
	}
}

export default App;
