import React from 'react';

import balans from './images/balans.jpg';
import reduktor from './images/reduktor.jpg';
import up from './images/up.jpg';
import spacemind from './images/spacemind.jpg';
import kids from './images/kids.jpg';

import { useHistory } from 'react-router-dom';
import logo from './images/logo2.png';
import API from './util/api';

function Select() {
	let history = useHistory();

	const goTo = w => () => {
		history.push('/' + w);
	};

	const logout = () => {
		API.logout().then(() => {
			window.location.reload(true);
		});
	};

	return (
		<div className="select">
			<div className="gradient"></div>
			<div className="elements">
				<img src={logo} className="app-logo" alt="logo" />
				<div className="select-label">
					WYBIERZ
					<br />
					MEDYTACJE
				</div>
				<div className="element">
					<div className="element-label balans-label">BALANS</div>
					<img onClick={goTo('balans')} src={balans} className="element-img" alt="" />
				</div>
				<div className="element">
					<div className="element-label reduktor-label">REDUKTOR</div>
					<img onClick={goTo('reduktor')} src={reduktor} className="element-img" alt="" />
				</div>
				<div className="element">
					<div className="element-label up-label">UP</div>
					<img onClick={goTo('up')} src={up} className="element-img" alt="" />
				</div>
				<div className="element">
					<div className="element-label spacemind-label">SPACEMIND</div>
					<img onClick={goTo('spacemind')} src={spacemind} className="element-img" alt="" />
				</div>
				<div className="element">
					<div className="element-label kids-label">KIDS</div>
					<img onClick={goTo('kids')} src={kids} className="element-img" alt="" />
				</div>
				<div onClick={logout} className="logout-button">
					Wylogowanie
				</div>
			</div>
		</div>
	);
}

export default Select;
