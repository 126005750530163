import React, { createRef, useState, useEffect } from 'react';
import logo from './images/logo.png';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { faPlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';
import moment from 'moment';

import config from './util/config';

import ReactHowler from './react-howler-2';

function Medi(props) {
	let history = useHistory();

	const rh = createRef();

	// eslint-disable-next-line
	const [playingNow, setPlayingNow] = useState(false);
	// eslint-disable-next-line
	const [variant, setVariant] = useState(1);
	const [currTime, setCurrTime] = useState(0);
	const [durationTime, setDurationTime] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (rh && rh.current) {
				let v1 = rh.current.seek();
				let v2 = rh.current.duration();
				if (_.isNumber(v1)) {
					setCurrTime(v1);
				}
				if (_.isNumber(v2)) {
					setDurationTime(v2);
				}
			}
		}, 500);

		return function cleanup() {
			clearInterval(interval);
		};
	});

	const playingEnd = () => {
		console.log('playing ended ...');
	};

	const formatSeconds = s => {
		let sp = s;
		sp = durationTime - s;
		return moment.utc(moment.duration(sp, 'seconds').asMilliseconds()).format('m:ss');
	};

	return (
		<div className="medi">
			<div className="gradient"></div>
			<div className="medi-data">
				<div className="medi-header">
					{/* <Link to="/">M1</Link> */}
					<div className="logo-iniside">
						<img src={logo} className="app-logo" alt="logo" />
					</div>
					<div
						className="medi-label"
						onClick={() => {
							history.push('/select');
						}}
					>
						<FontAwesomeIcon icon={faLongArrowAltLeft} />
						&nbsp;&nbsp;
						{props.name}
					</div>
				</div>
				<div className="middle-info">
					<div>
						<div className="middle-label">CZAS</div>
						<div className="time-label">{formatSeconds(currTime)}</div>
					</div>
				</div>
				<div className="bottom-panel-info">
					<div className="selector">
						<div
							onClick={() => {
								setVariant(1);
							}}
							className={'selector-element' + (variant === 1 ? ' active' : '')}
						>
							BARTEK
						</div>
						<div className={'selector-element separator'}>/</div>
						<div
							onClick={() => {
								setVariant(2);
							}}
							className={'selector-element' + (variant === 2 ? ' active' : '')}
						>
							NATASZA
						</div>
					</div>
					<div>
						<FontAwesomeIcon
							onClick={() => {
								setPlayingNow(!playingNow);
							}}
							icon={playingNow ? faPause : faPlay}
						/>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<FontAwesomeIcon
							onClick={() => {
								setPlayingNow(false);
								rh.current.seek(0);
							}}
							icon={faStop}
						/>
					</div>
				</div>
			</div>

			<ReactHowler
				ref={rh}
				src={config.api_url + 'audio/' + props.name + '_' + variant + '.mp3'}
				onEnd={playingEnd}
				playing={playingNow}
				html5={true}
			/>
		</div>
	);
}

export default Medi;
