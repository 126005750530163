import React, { useState } from 'react';
import logo from './images/logo2.png';

import { useHistory } from 'react-router-dom';
import API from './util/api';

function Login(props) {
	let history = useHistory();

	// const [username, setUsername] = useState('admin');
	// const [password, setPassword] = useState('sa');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleChangeUsername = e => {
		setUsername(e.target.value);
	};
	const handleChangePassword = e => {
		setPassword(e.target.value);
	};

	const authUser = () => {
		API.authUser(username, password)
			.then(v => {
				if (!v || v.err) {
					alert("błędne dane");
				} else {
					props.luState[1](v);
					history.push('/select');
				}
			})
			.catch(e => {
				alert(e);
			});
	};

	return (
		<div className="login">
			<div className="login-rect">
				<img src={logo} className="app-logo" alt="logo" />
				<div className="auth-label-text">Nazwa użytkownika</div>
				<div>
					<input className="auth-input" value={username} onChange={handleChangeUsername}></input>
				</div>
				<div className="auth-label-text">Hasło</div>
				<div>
					<input
						className="auth-input"
						onChange={handleChangePassword}
						value={password}
						type="password"
					></input>
				</div>
				<div
					onClick={authUser}
					className="login-button"
				>
					Wejdź
				</div>
			</div>
		</div>
	);
}

export default Login;
