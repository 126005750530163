// import React, { useState } from 'react';
import config from './config';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

let encodeDates = data => {
	return _.cloneDeepWith(data, v => {
		if (_.isDate(v)) {
			return { _date: moment(v).toISOString() };
		}
	});
};
let decodeDates = data => {
	return _.cloneDeepWith(data, v => {
		if (_.isObject(v) && v.hasOwnProperty('_date')) {
			return moment(v._date).toDate();
		}
	});
};

export default {
	authUser: (email, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(config.api_url + 'auth', { username: email, password: password }, { withCredentials: true })
				.then(function(response) {
					let ud = decodeDates(response).data;
					resolve(ud);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	getLoggedUser: () => {
		return new Promise((resolve, reject) => {
			axios
				.get(config.api_url + 'auth/getLoggedUser', { withCredentials: true })
				.then(function(response) {
					resolve(decodeDates(response));
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	logout: () => {
		return new Promise((resolve, reject) => {
			axios
				.get(config.api_url + 'auth/logout', { withCredentials: true })
				.then(function(response) {
					resolve(decodeDates(response));
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	licenses: () => {
		return new Promise((resolve, reject) => {
			axios
				.get(config.api_url + 'licenses', { withCredentials: true })
				.then(function(response) {
					resolve(decodeDates(response));
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	updateLicense: (original, updated) => {
		return new Promise((resolve, reject) => {
			axios
				.post(config.api_url + 'licenses/update', encodeDates({ original, updated }), { withCredentials: true })
				.then(function(response) {
					let ud = decodeDates(response).data;
					resolve(ud);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	usersFilter: value => {
		return new Promise((resolve, reject) => {
			axios
				.post(config.api_url + 'filter', { type: 'users', filter: value }, { withCredentials: true })
				.then(function(response) {
					let ud = decodeDates(response).data;
					resolve(ud);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
};
